/* autoscroll start */
export default function (scheduler) {
  var scrollInterval = 10;
  var interval = null;
  var startPos = null;

  function getRelativeCoordinates(e, parent) {
    var view = scheduler.matrix[scheduler._mode];
    var pos = {};
    var offset = {};
    var container = parent;
    pos.x = !!e.touches ? e.touches[0].pageX : e.pageX;
    pos.y = !!e.touches ? e.touches[0].pageY : e.pageY;
    offset.left = container.offsetLeft + view.dx;
    offset.top = container.offsetTop;

    while (container) {
      offset.left += container.offsetLeft;
      offset.top += container.offsetTop;
      container = container.offsetParent;
    }

    return {
      x: pos.x - offset.left,
      y: pos.y - offset.top
    };
  }

  function autoscrollInterval(event) {
    if (interval) clearInterval(interval);
    var view = scheduler.matrix[scheduler._mode];
    if (!view) return;
    scheduler._schedulerOuter = scheduler.$container.querySelector(".dhx_timeline_data_wrapper");

    if (!view.scrollable) {
      scheduler._schedulerOuter = scheduler.$container.querySelector(".dhx_cal_data");
    }

    var eventPos = {
      pageX: !!event.touches ? event.touches[0].pageX : event.pageX,
      pageY: !!event.touches ? event.touches[0].pageY : event.pageY
    };
    interval = setInterval(function () {
      tick(eventPos);
    }, scrollInterval);
  }

  function tick(e) {
    if (!scheduler.getState().drag_id) {
      clearInterval(interval);
      startPos = null;
      return;
    }

    var view = scheduler.matrix[scheduler._mode];
    if (!view) return;
    var viewport = scheduler._schedulerOuter;
    var box = getRelativeCoordinates(e, viewport);
    var availWidth = viewport.offsetWidth - view.dx;
    var availHeight = viewport.offsetHeight;
    var posX = box.x;
    var posY = box.y;
    var settings = view.autoscroll || {};

    if (settings === true) {
      settings = {};
    }

    scheduler._merge(settings, {
      range_x: 200,
      // px to edge
      range_y: 100,
      speed_x: 20,
      // speed
      speed_y: 10
    });

    var scrollLeft = need_scroll(posX, availWidth, startPos ? startPos.x : 0, settings.range_x);

    if (!view.scrollable) {
      scrollLeft = 0;
    }

    var scrollTop = need_scroll(posY, availHeight, startPos ? startPos.y : 0, settings.range_y);

    if ((scrollTop || scrollLeft) && !startPos) {
      startPos = {
        x: posX,
        y: posY
      };
      scrollLeft = 0;
      scrollTop = 0;
    }

    scrollLeft = scrollLeft * settings.speed_x;
    scrollTop = scrollTop * settings.speed_y;

    if (scrollLeft && scrollTop) {
      if (Math.abs(scrollLeft / 5) > Math.abs(scrollTop)) {
        scrollTop = 0;
      } else if (Math.abs(scrollTop / 5) > Math.abs(scrollLeft)) {
        scrollLeft = 0;
      }
    }

    if (scrollLeft || scrollTop) {
      startPos.started = true;
      scroll(scrollLeft, scrollTop);
    } else {
      clearInterval(interval);
    }
  }

  function need_scroll(pos, boxSize, startCoord, scrollRange) {
    if (pos < scrollRange && (!startPos || startPos.started || pos < startCoord)) {
      return -1;
    } else if (boxSize - pos < scrollRange && (!startPos || startPos.started || pos > startCoord)) {
      return 1;
    }

    return 0;
  }

  function scroll(left, top) {
    var viewport = scheduler._schedulerOuter;

    if (top) {
      viewport.scrollTop += top;
    }

    if (left) {
      // + 40 - adjust height of that movement was correct
      viewport.scrollLeft += left;
    }
  }

  var evId = scheduler.attachEvent("onSchedulerReady", function () {
    if (scheduler.matrix) {
      scheduler.event(document.body, "mousemove", autoscrollInterval);
      scheduler.detachEvent(evId);
    }
  });
  /* autoscroll end */
}