import keyboard_shortcuts from "./keyboard_navigation/common/keyboard_shortcuts";
import eventhandler from "./keyboard_navigation/common/eventhandler";
import trap_modal_focus from "./keyboard_navigation/common/trap_modal_focus";
import marker from "./keyboard_navigation/marker";
import scheduler_node from "./keyboard_navigation/elements/scheduler_node";
import nav_node from "./keyboard_navigation/elements/nav_node";
import header_cell from "./keyboard_navigation/elements/header_cell";
import event from "./keyboard_navigation/elements/event";
import time_slot from "./keyboard_navigation/elements/time_slot";
import minical_button from "./keyboard_navigation/elements/minical_button";
import minical_cell from "./keyboard_navigation/elements/minical_cell";
import data_area from "./keyboard_navigation/elements/data_area";
import modals from "./keyboard_navigation/modals";
import core from "./keyboard_navigation/core";
import key_nav_legacy from "./keyboard_navigation/key_nav_legacy";
import scheduler_handlers from "./keyboard_navigation/scheduler_handlers";
import minical_handlers from "./keyboard_navigation/minical_handlers";
export default function (scheduler) {
  scheduler.config.key_nav = true;
  scheduler.config.key_nav_step = 30;

  scheduler.addShortcut = function (shortcut, handler, scope) {
    var scopeObject = getScope(scope);

    if (scopeObject) {
      scopeObject.prototype.bind(shortcut, handler);
    }
  };

  scheduler.getShortcutHandler = function (shortcut, scope) {
    var scopeObject = getScope(scope);

    if (scopeObject) {
      var commands = scheduler.$keyboardNavigation.shortcuts.parse(shortcut);

      if (commands.length) {
        return scopeObject.prototype.findHandler(commands[0]);
      }
    }
  };

  scheduler.removeShortcut = function (shortcut, scope) {
    var scopeObject = getScope(scope);

    if (scopeObject) {
      scopeObject.prototype.unbind(shortcut);
    }
  };

  scheduler.focus = function () {
    if (!scheduler.config.key_nav) {
      return;
    }

    var disp = scheduler.$keyboardNavigation.dispatcher;
    disp.enable();
    var activeNode = disp.getActiveNode();

    if (!activeNode || activeNode instanceof scheduler.$keyboardNavigation.MinicalButton || activeNode instanceof scheduler.$keyboardNavigation.MinicalCell) {
      disp.setDefaultNode();
    } else {
      disp.focusNode(disp.getActiveNode());
    }
  };

  function getScope(mode) {
    var scopes = {
      "minicalButton": scheduler.$keyboardNavigation.MinicalButton,
      "minicalDate": scheduler.$keyboardNavigation.MinicalCell,
      "scheduler": scheduler.$keyboardNavigation.SchedulerNode,
      "dataArea": scheduler.$keyboardNavigation.DataArea,
      "timeSlot": scheduler.$keyboardNavigation.TimeSlot,
      "event": scheduler.$keyboardNavigation.Event
    };
    var searchMap = {};

    for (var i in scopes) {
      searchMap[i.toLowerCase()] = scopes[i];
    }

    mode = (mode + "").toLowerCase();
    return searchMap[mode] || scopes.scheduler;
  }

  scheduler.$keyboardNavigation = {};

  scheduler._compose = function () {
    var parts = Array.prototype.slice.call(arguments, 0);
    var res = {};

    for (var i = 0; i < parts.length; i++) {
      var obj = parts[i];

      if (typeof obj == "function") {
        obj = new obj();
      }

      for (var p in obj) {
        res[p] = obj[p];
      }
    }

    return res;
  };

  keyboard_shortcuts(scheduler);
  eventhandler(scheduler);
  trap_modal_focus(scheduler);
  marker(scheduler);
  scheduler_node(scheduler);
  nav_node(scheduler);
  header_cell(scheduler);
  event(scheduler);
  time_slot(scheduler);
  minical_button(scheduler);
  minical_cell(scheduler);
  data_area(scheduler);
  modals(scheduler);
  core(scheduler);
  key_nav_legacy(scheduler);

  (function () {
    scheduler_handlers(scheduler);
    minical_handlers(scheduler);
    var dispatcher = scheduler.$keyboardNavigation.dispatcher;
    scheduler.$keyboardNavigation.attachSchedulerHandlers();

    if (scheduler.renderCalendar) {
      // if minical ext loaded before key nav ext - patch it now
      scheduler.$keyboardNavigation.patchMinicalendar();
    } else {
      // otherwise - wait until everything is loaded and try again
      var attachOnce = scheduler.attachEvent("onSchedulerReady", function () {
        scheduler.detachEvent(attachOnce);
        scheduler.$keyboardNavigation.patchMinicalendar();
      });
    }

    function isSchedulerSelected() {
      if (!scheduler.config.key_nav) return;
      var enable;
      var focusElement = document.activeElement; // halt key nav when focus is outside scheduler or in quick info popup

      if (!focusElement || scheduler.utils.dom.locateCss(focusElement, "dhx_cal_quick_info", false)) {
        enable = false;
      } else {
        enable = scheduler.$keyboardNavigation.isChildOf(focusElement, scheduler.$container) || scheduler.$keyboardNavigation.isMinical(focusElement);
      }

      return enable;
    }

    function changeState(enable) {
      if (enable && !dispatcher.isEnabled()) {
        dispatcher.enable();
      } else if (!enable && dispatcher.isEnabled()) {
        dispatcher.disable();
      }
    }

    setInterval(function () {
      if (!scheduler.$container || !scheduler.$keyboardNavigation.isChildOf(scheduler.$container, document.body)) {
        return;
      }

      var enable = isSchedulerSelected();

      if (enable) {
        changeState(enable);
      } else if (!enable && dispatcher.isEnabled()) {
        setTimeout(function () {
          if (scheduler.$destroyed) {
            return;
          } // doublecheck in case checking is done in handler before focus element is repainted


          if (scheduler.config.key_nav) {
            changeState(isSchedulerSelected());
          } else {
            scheduler.$container.removeAttribute("tabindex");
          }
        }, 100);
      }
    }, 500);
  })();
  /*	if(window.Scheduler && window.Scheduler.plugin){
  		window.Scheduler.plugin(setupKeyNav);
  	}else{
  		setupKeyNav(window.scheduler);
  	}*/

}